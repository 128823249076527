function documentTabSlider(sliderNode) {
    if (sliderNode === undefined || !sliderNode) {
        console.log('documentTabSlider: inavlid sliderNode recieved', sliderNode);
        return false;
    }

    function toggleSlider(curretnBreakpoint) {
        if (curretnBreakpoint === 767) {
            if ($(sliderNode).hasClass('disabled-slider')) {
                $(sliderNode).removeClass('disabled-slider');
            }
        } else {
            if (!$(sliderNode).hasClass('disabled-slider')) {
                $(sliderNode).addClass('disabled-slider');
            }
        }
    }

    $(sliderNode).on('init', function(event, slick){
        toggleSlider(slick.activeBreakpoint);
    });

    $(sliderNode).slick({
        slidesToShow: 10,
        slidesToScroll: 1,
        arrows: false,
        variableWidth: true,
        dots: false,
        infinite: false,
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 1599,
                settings: {

                }
			},
            {
                breakpoint: 1023,
                settings: {

                }
			},
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                }
			},
    	]
    });

    $(sliderNode).on('breakpoint', function(event, slick, breakpoint){
        toggleSlider(breakpoint);
    });

}

document.addEventListener('DOMContentLoaded', function() {
    const documentTabSliderNodes = document.getElementsByClassName('document-tab-slider');
    for(let i = 0; i < documentTabSliderNodes.length; i++) {
        let documentTabSliderNode = documentTabSliderNodes.item(i);
        const documentTabSliderObj = new documentTabSlider(documentTabSliderNode);
    }
});
