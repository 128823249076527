let commons = require('../utils/commons');

function initCareerDropdown(mobileFilterNode) {
    const careerDropdownElements = mobileFilterNode.getElementsByClassName('career');
    if (careerDropdownElements.length <= 0) {
        console.error('initCareerDropdown: no careerDropdownElements found by class "career"');
        return false;
    }
    let closeAll = function () {
        for(let i = 0; i < careerDropdownElements.length; i++) {
            let careerDropdownElement = careerDropdownElements.item(i);
            commons.removeClassName(careerDropdownElement, 'open');
            setTimeout(function () {
                commons.removeClassName(careerDropdownElement, 'animated');
            }, 300);
        }
    }
    let toggle = function(element) {
        if (commons.hasClassName(element, 'open')) {
            commons.removeClassName(element, 'open');
            setTimeout(function () {
                commons.removeClassName(element, 'animated');
            }, 1200);
        } else {
            commons.addClassName(element, 'animated');
            setTimeout(function () {
                commons.addClassName(element, 'open');
            }, 100);
        }
    }
    for(let i = 0; i < careerDropdownElements.length; i++) {
       let careerDropdownElement = careerDropdownElements.item(i);
       let linkOpen = careerDropdownElement.getElementsByClassName('opener')[0];
       if (linkOpen === undefined || !linkOpen) {
           console.error('initCareerDropdown: linkOpen not found by class "opener" in ', careerDropdownElement);
           return false;
       }
       linkOpen.addEventListener('click', function(e){
           e.preventDefault();
           toggle(careerDropdownElement);
       });
    }
}

document.addEventListener('DOMContentLoaded', function(){
    const careerContainers = document.getElementsByClassName('career-list-description');
    for(let i = 0; i < careerContainers.length; i++) {
        let careerContainer = careerContainers.item(i);
        initCareerDropdown(careerContainer);
    }
})
