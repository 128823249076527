import indexStyles from '../sass/common.scss';
let commons = require('./utils/commons');
import projectsSliderJS from './includes/projects_slider.js';
import personnelSliderJS from './includes/personnel_slider.js';
import presentationSliderJS from './includes/presentation_slider.js';
import productDetailSliderJS from './includes/product_detail_slider.js';
import parametersSliderJS from './includes/parameters_slider.js';
import customTabsJS from './includes/custom_tabs.js';
import selectorDropdownJS from './includes/selector_dropdown.js';
import careerDropdownJS from './includes/career_dropdown.js';
import madeProjectImagesSliderJS from './includes/made_project_images_slider.js';
import documentTabSliderJS from './includes/document-tab-slider.js';
import addNewsSliderJS from './includes/add_news_slider.js';
import xsPseudoTableSliderJS from './includes/xs_pseudo_table_slider.js';
import serviceMenuSliderJS from './includes/service_menu_slider.js';
import about1IMG from '../img/about-1.jpg';
import companyDescIMG from '../img/company-desc.jpg';
import activitiesIMG from '../img/activities.jpg';
import projectsMapProject1IMG from '../img/projects-map-project-1.jpg';
import companyPointsIMG from '../img/company_points.jpg';
import personnel1IMG from '../img/personnel-1.jpg';
import personnel2IMG from '../img/personnel-2.jpg';
import personnel3IMG from '../img/personnel-3.jpg';
import personnel4IMG from '../img/personnel-4.jpg';
import partners1IMG from '../img/partners-1.svg';
import partners2IMG from '../img/partners-2.svg';
import partners3IMG from '../img/partners-3.svg';
import partners4IMG from '../img/partners-4.svg';
import partners5IMG from '../img/partners-5.svg';
import partners6IMG from '../img/partners-6.svg';
import partners7IMG from '../img/partners-7.svg';
import partners8IMG from '../img/partners-8.png';
import newsBig1IMG from '../img/news-big-1.jpg';
import presentation1IMG from '../img/presentation-1.png';
import presentation2IMG from '../img/presentation-2.jpg';
import productsAndServicesIMG from '../img/products-and-services.jpg';
import services7IMG from '../img/services-7.jpg';
import services8IMG from '../img/services-8.jpg';
import services9IMG from '../img/services-9.jpg';
import services10IMG from '../img/services-10.jpg';
import productDetail1IMG from '../img/product-detail-1.jpg';
import productImage1IMG from '../img/product-image-1.png';
import productImage2IMG from '../img/product-image-2.jpg';
import autistemLogoIMG from '../img/autistem-logo.png';
import accent1IMG from '../img/accent-1.jpg';
import item1IMG from '../img/item-1.png';
import item2IMG from '../img/item-2.png';
import item3IMG from '../img/item-3.png';
import item4IMG from '../img/item-4.png';
import item5IMG from '../img/item-5.png';
import environmentBkgIMG from '../img/environment-bkg.jpg';
import environment1IMG from '../img/environment-1.jpg';
import environment2IMG from '../img/environment-2.jpg';
import environment3IMG from '../img/environment-3.jpg';
import mskBkgIMG from '../img/msk-bkg.jpg';
import mapSmallIMG from '../img/map-small.jpg';
import careerImage1IMG from '../img/career-image-1.jpg';
import characteristicsImage1IMG from '../img/characteristics-image-1.jpg';
import madeProjects1IMG from '../img/made-projects-1.jpg';
import madeProjects2IMG from '../img/made-projects-2.jpg';
import madeProjectDetail1IMG from '../img/made-project-detail-1.jpg';
import madeProjectDetail2IMG from '../img/made-project-detail-2.jpg';
import madeProjectDetail3IMG from '../img/made-project-detail-3.jpg';
import madeProjectDetail4IMG from '../img/made-project-detail-4.jpg';
import madeProjectDetail5IMG from '../img/made-project-detail-5.jpg';
import madeProjectDetail6IMG from '../img/made-project-detail-6.jpg';
import madeProjectDetail7IMG from '../img/made-project-detail-7.jpg';
import madeProjectDetail8IMG from '../img/made-project-detail-8.jpg';
import subType1IMG from '../img/sub-type-1.jpg';
import subType2IMG from '../img/sub-type-2.jpg';
import subType3IMG from '../img/sub-type-3.jpg';
import addServices1IMG from '../img/add-services-1.jpg';
import addServices2IMG from '../img/add-services-2.jpg';
import progress1IMG from '../img/progress-1.jpg';
import progressStage1IMG from '../img/progress-stage-1.jpg';
import progressStage2IMG from '../img/progress-stage-2.jpg';
import progressStage3IMG from '../img/progress-stage-3.jpg';
import progressStage4IMG from '../img/progress-stage-4.jpg';
import progressStage1SubIMG from '../img/progress-stage-1-sub.png';
import progressStage3SubIMG from '../img/progress-stage-3-sub.png';
import progressStage4SubIMG from '../img/progress-stage-4-sub.png';
import imageCollection1IMG from '../img/image-collection-1.jpg';
import imageCollection2IMG from '../img/image-collection-2.jpg';
import notFoundBgIMG from '../img/404-bg.png';
import notFoundPicIMG from '../img/404-pic.png';
