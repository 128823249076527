function serviceMenuSlider(sliderNode) {
    const prevButton = sliderNode.getElementsByClassName('prev')[0];
    const nextButton = sliderNode.getElementsByClassName('next')[0];
    if (prevButton === undefined || !prevButton) {
        console.log('serviceMenuSlider: no prevButton found by class "prev"');
        return false;
    }
    if (nextButton === undefined || !nextButton) {
        console.log('serviceMenuSlider: no nextButton found by class "next"');
        return false;
    }
    const sliderContainer = sliderNode.getElementsByClassName('service-menu-slider-viewport')[0];
    if (sliderContainer === undefined || !sliderContainer) {
        console.log('projectsSlider: no sliderContainer found by class "slider-viewport"')
        return false;
    }

    function toggleSlider(currentWidth) {
        if (currentWidth <= 767 || currentWidth <= 1599) {
            if ($(sliderContainer).hasClass('disabled-slider')) {
                $(sliderContainer).removeClass('disabled-slider');
            }
        } else {
            if (!$(sliderContainer).hasClass('disabled-slider')) {
                $(sliderContainer).addClass('disabled-slider');
            }
        }
    }

    $(sliderContainer).on('init', function(event, slick){
        toggleSlider(slick.listWidth);
    });

    $(sliderContainer).slick({ //images-background
        slidesToShow: 10,
        slidesToScroll: 1,
        arrows: false,
        variableWidth: true,
        dots: false,
        nextArrow: nextButton,
        prevArrow: prevButton,
        infinite: false,
        responsive: [
            {
                breakpoint: 1599,
                settings: {
                    slidesToShow: 1,
                    arrows: true,
                    centerMode: false
                }
			},
            {
                breakpoint: 1023,
                settings: {

                }
			},
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    arrows: true,
                    centerMode: true
                }
			},
    	]
    });

    $(sliderContainer).on('breakpoint', function(event, slick, breakpoint){
        toggleSlider(breakpoint);
    });

}

document.addEventListener('DOMContentLoaded', function() {
    const serviceMenuSliderNodes = document.getElementsByClassName('service-menu-slider');
    for(let i = 0; i < serviceMenuSliderNodes.length; i++) {
        let serviceMenuSliderNode = serviceMenuSliderNodes.item(i);
        const serviceMenuSliderObj = new serviceMenuSlider(serviceMenuSliderNode);
    }
});
