function xsPseudoTableSlider(sliderNode) {

    $(sliderNode).slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        variableWidth: false,
        dots: false,
        infinite: false,
        responsive: [
            {
                breakpoint: 1599,
                settings: {

                }
			},
            {
                breakpoint: 1023,
                settings: {

                }
			},
            {
                breakpoint: 767,
                settings: {

                }
			},
    	]
    });

}

document.addEventListener('DOMContentLoaded', function() {
    const xsPseudoTableSliderNodes = document.getElementsByClassName('xs-pseudo-table-slider');
    for(let i = 0; i < xsPseudoTableSliderNodes.length; i++) {
        let xsPseudoTableSliderNode = xsPseudoTableSliderNodes.item(i);
        const xsPseudoTableSliderObj = new xsPseudoTableSlider(xsPseudoTableSliderNode);
    }
});
