function personnelSlider(sliderNode) {
    const sliderNavs = sliderNode.getElementsByClassName('navs')[0];
    if (sliderNavs === undefined || !sliderNavs) {
        console.log('personnelSlider: no sliderNavs found by class "navs"');
        return false;
    }
    const prevButton = sliderNode.getElementsByClassName('prev')[0];
    const nextButton = sliderNode.getElementsByClassName('next')[0];
    if (prevButton === undefined || !prevButton) {
        console.log('personnelSlider: no prevButton found by class "prev"');
        return false;
    }
    if (nextButton === undefined || !nextButton) {
        console.log('personnelSlider: no nextButton found by class "next"');
        return false;
    }
    const sliderContainer = sliderNode.getElementsByClassName('slider-viewport')[0];
    if (sliderContainer === undefined || !sliderContainer) {
        console.log('personnelSlider: no sliderContainer found by class "slider-viewport"')
        return false;
    }

    $(sliderContainer).slick({ //images-background
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        variableWidth: false,
        dots: false,
        dotsClass: "custom-nav",
        appendDots: sliderNavs,
        nextArrow: nextButton,
        prevArrow: prevButton,
        infinite: true,
        responsive: [
            {
                breakpoint: 1599,
                settings: {
                    slidesToShow: 3
                }
			},
            {
                breakpoint: 1023,
                settings: {

                }
			},
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1
                }
			},
    	]
    });

}

document.addEventListener('DOMContentLoaded', function() {
    const personnelSliderNodes = document.getElementsByClassName('personnel-slider');
    for(let i = 0; i < personnelSliderNodes.length; i++) {
        let personnelSliderNode = personnelSliderNodes.item(i);
        const personnelSliderObj = new personnelSlider(personnelSliderNode);
    }
});
