function projectsSlider(sliderNode) {
    const sliderNavs = sliderNode.getElementsByClassName('navs')[0];
    if (sliderNavs === undefined || !sliderNavs) {
        console.log('projectsSlider: no sliderNavs found by class "navs"');
        return false;
    }
    const prevButton = sliderNode.getElementsByClassName('prev')[0];
    const nextButton = sliderNode.getElementsByClassName('next')[0];
    if (prevButton === undefined || !prevButton) {
        console.log('projectsSlider: no prevButton found by class "prev"');
        return false;
    }
    if (nextButton === undefined || !nextButton) {
        console.log('projectsSlider: no nextButton found by class "next"');
        return false;
    }
    const sliderContainer = sliderNode.getElementsByClassName('slider-viewport')[0];
    if (sliderContainer === undefined || !sliderContainer) {
        console.log('projectsSlider: no sliderContainer found by class "slider-viewport"')
        return false;
    }

    const pages = sliderNode.getElementsByClassName('pages')[0];
    if (pages === undefined || !pages) {
        console.log('blogSliderInit: no pages found by class "pages"');
        return false;
    }

    $(sliderContainer).on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
      //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
      var i = (currentSlide ? currentSlide : 0) + 1
      var total = slick.slideCount;
      if (i < 10) {
          i = '0' + i;
      }
      if (total < 10) {
          total = '0' + total;
      }
      $(pages).html('<span class="current">' + i + '</span>' + ' &mdash; ' + total);
    });

    $(sliderContainer).slick({ //images-background
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        variableWidth: false,
        dots: false,
        dotsClass: "custom-nav",
        appendDots: sliderNavs,
        nextArrow: nextButton,
        prevArrow: prevButton,
        infinite: false
    });

}

document.addEventListener('DOMContentLoaded', function() {
    const projectsSliderNodes = document.getElementsByClassName('projects-slider');
    for(let i = 0; i < projectsSliderNodes.length; i++) {
        let projectsSliderNode = projectsSliderNodes.item(i);
        const projectsSliderObj = new projectsSlider(projectsSliderNode);
    }
});
