function parametersSlider(sliderNode) {
    const sliderNavs = sliderNode.getElementsByClassName('navs')[0];
    if (sliderNavs === undefined || !sliderNavs) {
        console.log('parametersSlider: no sliderNavs found by class "navs"');
        return false;
    }
    const prevButton = sliderNode.getElementsByClassName('prev')[0];
    const nextButton = sliderNode.getElementsByClassName('next')[0];
    if (prevButton === undefined || !prevButton) {
        console.log('parametersSlider: no prevButton found by class "prev"');
        return false;
    }
    if (nextButton === undefined || !nextButton) {
        console.log('parametersSlider: no nextButton found by class "next"');
        return false;
    }
    const sliderContainer = sliderNode.getElementsByClassName('slider-viewport')[0];
    if (sliderContainer === undefined || !sliderContainer) {
        console.log('parametersSlider: no sliderContainer found by class "slider-viewport"')
        return false;
    }

    const pages = sliderNode.getElementsByClassName('pages')[0];
    if (pages === undefined || !pages) {
        console.log('parametersSlider: no pages found by class "pages"');
        return false;
    }

    $(sliderContainer).on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
      //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
      var i = (currentSlide ? currentSlide : 0) + 1
      var total = slick.slideCount;
      $(pages).html('<a href="#" class="all-photos">Все фото (' + total + ')</a>');
    });

    $(sliderContainer).slick({ //images-background
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        variableWidth: false,
        dots: false,
        dotsClass: "custom-nav",
        appendDots: sliderNavs,
        nextArrow: nextButton,
        prevArrow: prevButton,
        infinite: true
    });

}

document.addEventListener('DOMContentLoaded', function() {
    const parametersSliderNodes = document.getElementsByClassName('parameters-slider');
    for(let i = 0; i < parametersSliderNodes.length; i++) {
        let parametersSliderNode = parametersSliderNodes.item(i);
        const parametersSliderObj = new parametersSlider(parametersSliderNode);
    }
});
