function madeProjectImagesSlider(sliderNode) {
    //preview-slider
    const previewSlider = sliderNode.getElementsByClassName('preview-slider')[0];
    if (previewSlider === undefined || !previewSlider) {
        console.log('madeProjectImagesSlider: no previewSlider found by class "preview-slider"');
        return false;
    }
    const previewSliderNavs = previewSlider.getElementsByClassName('navs')[0];
    if (previewSliderNavs === undefined || !previewSliderNavs) {
        console.log('madeProjectImagesSlider: no previewSliderNavs found by class "navs"');
        return false;
    }
    const previewSliderPrevButton = previewSlider.getElementsByClassName('prev')[0];
    const previewSliderNextButton = previewSlider.getElementsByClassName('next')[0];
    if (previewSliderPrevButton === undefined || !previewSliderPrevButton) {
        console.log('madeProjectImagesSlider: no previewSliderPrevButton found by class "prev"');
        return false;
    }
    if (previewSliderNextButton === undefined || !previewSliderNextButton) {
        console.log('madeProjectImagesSlider: no previewSliderNextButton found by class "next"');
        return false;
    }
    const previewSliderContainer = previewSlider.getElementsByClassName('slider-viewport')[0];
    if (previewSliderContainer === undefined || !previewSliderContainer) {
        console.log('madeProjectImagesSlider: no previewSliderContainer found by class "slider-viewport"')
        return false;
    }

    if ($(previewSliderContainer).hasClass("slick-initialized")) {
        console.log('madeProjectImagesSlider: slick already inicialized on ', sliderNode);
        return false;
    }

    const progressBar = previewSlider.getElementsByClassName('progress-bar')[0];
    if (progressBar === undefined || !progressBar) {
        console.log('madeProjectImagesSlider: no progressBar found by class "progress-bar"');
        return false;
    }

    //images-background
    const imagesSlider = sliderNode.getElementsByClassName('images-slider')[0];
    if (imagesSlider === undefined || !imagesSlider) {
        console.log('madeProjectImagesSlider: no previewSlider found by class "background-images-slider"');
        return false;
    }
    const imagesSliderNavs = imagesSlider.getElementsByClassName('navs')[0];
    if (imagesSliderNavs === undefined || !imagesSliderNavs) {
        console.log('madeProjectImagesSlider: no previewSliderNavs found by class "navs"');
        return false;
    }
    const imagesSliderPrevButton = imagesSlider.getElementsByClassName('prev')[0];
    const imagesSliderNextButton = imagesSlider.getElementsByClassName('next')[0];
    if (imagesSliderPrevButton === undefined || !imagesSliderPrevButton) {
        console.log('madeProjectImagesSlider: no imagesSliderPrevButton found by class "prev"');
        return false;
    }
    if (imagesSliderPrevButton === undefined || !imagesSliderPrevButton) {
        console.log('madeProjectImagesSlider: no imagesSliderPrevButton found by class "next"');
        return false;
    }
    const  imagesSliderContainer = imagesSlider.getElementsByClassName('slider-viewport')[0];
    if (imagesSliderContainer === undefined || !imagesSliderContainer) {
        console.log('madeProjectImagesSlider: no imagesSliderContainer found by class "slider-viewport"')
        return false;
    }

    if ($(imagesSliderContainer).hasClass("slick-initialized")) {
        console.log('madeProjectImagesSlider: slick already inicialized on ', sliderNode);
        return false;
    }

    $(previewSliderContainer).slick({ //preview-slider
        slidesToShow: 7,
        slidesToScroll: 1,
        asNavFor: imagesSliderContainer,
        focusOnSelect: true,
        dots: false,
        dotsClass: "custom-nav",
        fade: false,
        appendDots: previewSliderNavs,
        nextArrow: previewSliderNextButton,
        prevArrow: previewSliderPrevButton,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnFocus: false,
        pauseOnHover: false
    });

    $(imagesSliderContainer).slick({ //images-slider
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        asNavFor: previewSliderContainer,
        dots: false,
        fade: true,
        dotsClass: "custom-nav",
        appendDots: imagesSliderNavs,
        nextArrow: imagesSliderNextButton,
        prevArrow: imagesSliderPrevButton,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnFocus: false,
        pauseOnHover: false
    });

    $(previewSliderContainer).on('beforeChange', function(e, slick) {
        $(progressBar).removeClass("activate");
    });
    $(previewSliderContainer).on('afterChange', function(e, slick, nextSlide) {
        $(progressBar).addClass("activate");
    });

}

function madeProjectImagesSliderDestructor(sliderNode) {
    //preview-slider
    const previewSlider = sliderNode.getElementsByClassName('preview-slider')[0];
    if (previewSlider === undefined || !previewSlider) {
        console.log('madeProjectImagesSlider: no previewSlider found by class "preview-slider"');
        return false;
    }
    const previewSliderContainer = previewSlider.getElementsByClassName('slider-viewport')[0];
    if (previewSliderContainer === undefined || !previewSliderContainer) {
        console.log('madeProjectImagesSlider: no previewSliderContainer found by class "slider-viewport"')
        return false;
    }

    if ($(previewSliderContainer).hasClass("slick-initialized")) {
        $(previewSliderContainer).slick('unslick');
    }
    //images-background
    const imagesSlider = sliderNode.getElementsByClassName('images-slider')[0];
    if (imagesSlider === undefined || !imagesSlider) {
        console.log('madeProjectImagesSlider: no previewSlider found by class "background-images-slider"');
        return false;
    }
    const  imagesSliderContainer = imagesSlider.getElementsByClassName('slider-viewport')[0];
    if (imagesSliderContainer === undefined || !imagesSliderContainer) {
        console.log('madeProjectImagesSlider: no imagesSliderContainer found by class "slider-viewport"')
        return false;
    }

    if ($(imagesSliderContainer).hasClass("slick-initialized")) {
        $(imagesSliderContainer).slick('unslick');
    }
}

function initMadeProjectsFancybox(fancyboxLinkNode) {
    if (fancyboxLinkNode === undefined || !fancyboxLinkNode) {
        console.error('customFancyboxInit: invalid fancyboxLinkNode ', fancyboxLinkNode);
        return false;
    }
    let fancyboxContentId = fancyboxLinkNode.getAttribute("href");
    if (!fancyboxContentId || fancyboxContentId.length === 0) {
        console.error('customFancyboxInit: invalid fancyboxContentId from href attribute ', fancyboxLinkNode);
        return false;
    }
    fancyboxLinkNode.addEventListener('click', function(e) {
        $.fancybox.open({
            src  : fancyboxContentId,
            type : 'inline',
            opts : {
                buttons: [],
                baseClass: "custom-fancybox",
                afterShow: function( instance, slide ) {
                    let madeProjectImagesSliderNode = slide.$content.find('.made-project-images-slider')[0];
                    if (!madeProjectImagesSliderNode || madeProjectImagesSliderNode === undefined) {
                        return false;
                    }
                    let madeProjectImagesSliderObj = new madeProjectImagesSlider(madeProjectImagesSliderNode);
                },
                afterClose: function( instance, slide ) {
                    let madeProjectImagesSliderNode = slide.$content.find('.made-project-images-slider')[0];
                    if (!madeProjectImagesSliderNode || madeProjectImagesSliderNode === undefined) {
                        return false;
                    }
                    madeProjectImagesSliderDestructor(madeProjectImagesSliderNode);
                }
            },
        });
    });
}

document.addEventListener('DOMContentLoaded', function() {
    const madeProjectImagesSliderActivatorNodes = document.getElementsByClassName('made-project-images-slider-activator');
    for(let i = 0; i < madeProjectImagesSliderActivatorNodes.length; i++) {
        let madeProjectImagesSliderActivatorNode = madeProjectImagesSliderActivatorNodes.item(i);
        initMadeProjectsFancybox(madeProjectImagesSliderActivatorNode);
    }
});
