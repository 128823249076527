let commons = require('../utils/commons');

function initSelectorDropdown(selectorNode) {
    let toggle = function(element) {
        if (commons.hasClassName(element, 'open')) {
            commons.removeClassName(element, 'open');
            setTimeout(function () {
                commons.removeClassName(element, 'animated');
            }, 400);
        } else {
            commons.addClassName(element, 'animated');
            setTimeout(function () {
                commons.addClassName(element, 'open');
            }, 100);
        }
    }
   let linkOpen = selectorNode.getElementsByClassName('opener')[0];
   if (linkOpen === undefined || !linkOpen) {
       console.error('initSelectorDropdown: linkOpen not found by class "opener" in ', selectorNode);
       return false;
   }
   let linksClose = selectorNode.getElementsByClassName('close-selector');
   linkOpen.addEventListener('click', function(e){
       e.preventDefault();
       toggle(selectorNode);
   });
   for(let i = 0; i < linksClose.length; i++) {
       let linkClose = linksClose.item(i);
       linkClose.addEventListener('click', function(e){
           e.preventDefault();
           toggle(selectorNode);
       });
   }
}

document.addEventListener('DOMContentLoaded', function(){
    const selectorContainers = document.getElementsByClassName('selector-body');
    for(let i = 0; i < selectorContainers.length; i++) {
        let selectorContainer = selectorContainers.item(i);
        initSelectorDropdown(selectorContainer);
    }
})
